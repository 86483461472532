"use client";
import React from "react";
import { Image } from "antd";
import Link from "next/link";
import { usePathname } from "next/navigation";
import Cookies from "js-cookie";

// Import images
import sidefixedimage from "../../assets/images/red.gif";
import golderFixedImage from "../../assets/images/IS LIVE.gif";
// import PremierGif from "../../assets/images/Premier.gif";
import PremierGif from "../../assets/images/Premier (1).gif";
import PremierRedGif from "../../assets/images/Red Premier (1).gif";
import SpecialPremierGif from "../../assets/images/Premier-main.gif";
import RamadanGif from "../../assets/images/Ramadan.gif";
import SummerGif from "../../assets/images/summer_sale.gif";
import IslamicGif from "../../assets/images/islamic.gif";

const FixedButton = () => {
  const pathname = usePathname();
  let themeType = Cookies.get("user_type");

  // Determine image source based on theme type
  const getImageSource = () => {
    if (themeType === "premier") {
      return SpecialPremierGif.src;
    } else if (themeType === "islamic") {
      return IslamicGif.src;
    } else {
      return SummerGif.src;
    }
  };

  // Determine link destination based on theme type
  const getLinkDestination = () => {
    return themeType === "premier" ? "/premier" : "/11-11";
  };

  // Hide button on specific pages
  const shouldHideButton =
    pathname.includes("/11-11") ||
    pathname.includes("/cart") ||
    pathname.includes("/checkout");

  if (shouldHideButton) {
    return null;
  }

  return (
    <Link
      className="fixed-button"
      href={getLinkDestination()}
      style={{
        position: "fixed",
        right: 0,
        bottom: "18%",
        width: "120px",
        height: "120px",
        // transform: "translateY(-50%)",
        padding: "10px 20px",
        backgroundColor: "transparent",
        cursor: "pointer",
      }}
    >
      <Image
        preview={false}
        src={getImageSource()}
        height="100%"
        width="auto"
      />
    </Link>
  );
};

export default FixedButton;
